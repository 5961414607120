import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image, LinkBox, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Ласкаво просимо до Mobivgo
			</title>
			<meta name={"description"} content={"Завітайте до світу Mobivgo - притулку для ентузіастів тенісу та чемпіонів-початківців."} />
			<meta property={"og:title"} content={"Головна | Ласкаво просимо до Mobivgo"} />
			<meta property={"og:description"} content={"Завітайте до світу Mobivgo - притулку для ентузіастів тенісу та чемпіонів-початківців."} />
			<meta property={"og:image"} content={"https://mobivgo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://mobivgo.com/img/43623623.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://mobivgo.com/img/43623623.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://mobivgo.com/img/43623623.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://mobivgo.com/img/43623623.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://mobivgo.com/img/43623623.png"} />
			<meta name={"msapplication-TileImage"} content={"https://mobivgo.com/img/43623623.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://mobivgo.com/img/1.jpg) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="75%"
					md-width="100%"
					margin="0px 0px 400px 0px"
				>
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Ласкаво просимо до тенісного хабу
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
						Mobivgo
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					Більше про нас
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Чому варто обрати Mobivgo?

				</Text>
				<Text font="--lead" color="--darkL2" text-align="center" md-width="100%">
				Завітайте до світу Mobivgo - притулку для ентузіастів тенісу та чемпіонів-початківців. Наш хаб - це не просто гра в теніс, це життя в ньому, дихання ним і розвиток з кожним матчем. Завдяки прагненню до досконалості та пристрасті до спорту, Mobivgo - це найкраще місце для тенісних тренувань, товариського спілкування та безпрецедентного розвитку.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://mobivgo.com/img/2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Елітний тренерський склад
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Вчіться у найкращих з нашою командою досвідчених тренерів, які привносять у вашу гру персоналізовані стратегії та ідеї.

					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Сучасне обладнання
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Грайте на кортах преміум-класу, створених для оптимальної гри, незалежно від того, тренуєтесь ви, змагаєтесь чи просто насолоджуєтесь товариським матчем.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Дух спільноти
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Приєднуйтесь до яскравої спільноти любителів тенісу. Mobivgo - це більше, ніж клуб, це сім'я, яка підтримує і надихає кожного члена.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Індивідуальні тренувальні програми
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Від індивідуальних тренувань до групових занять, ми пропонуємо різноманітні програми, що відповідають вашому стилю, цілям та графіку.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="150px 0 150px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="40%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://mobivgo.com/img/3.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="65%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 56px/1.2 --fontFamily-serifGaramond"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Відчуйте переваги Mobivgo

					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="center">
					Тенісний хаб Mobivgo пропонує унікальне поєднання професійних тренувань, змагальної гри та дружньої атмосфери спільноти. Незалежно від того, чи берете ви ракетку в руки вперше, чи ви досвідчений гравець, який вдосконалює свої навички, наші програми розроблені таким чином, щоб підняти вашу гру на нові висоти.

					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});